
$(document).ready(function() {
  
  var owl = $('.owl-carousel');
  owl.owlCarousel({
    stagePadding: 50,
    loop: true,
    margin: 25,
    nav: true,
    responsive: {
      0: {
        items: 1,
        nav:true
      },
      480: {
        items: 2,
        nav:false
      },
      767: {
        items: 3,
        nav:true,
        loop:false
      },
      1000: {
        items: 3,
        nav:true,
        loop:false
      },
      1600: {
        items: 3,
      },
      1920: {
        items: 4,
        loop: false,
        margin: 20
      }
    }
  });
  $('.play').on('click', function() {
    owl.trigger('play.owl.autoplay', [1000])
  })
  $('.stop').on('click', function() {
    owl.trigger('stop.owl.autoplay')
  })
});


var owlObj = (function () {

  return {

    
loadCustomOwl: function() {
  $('.owl-carousel').each(function() {

    $this = $(this);
    $this.owlCarousel({
      stagePadding: 50,
      loop: true,
      margin: 45,
      nav: true,
      responsive: {
        0: {
          items: 1,
          nav:true,
          loop: false,
        },
        480: {
          items: 2,
          nav:false,
          loop: false,
        },
        767: {
          items: 3,
          nav:true,
          loop: false,
        },
        1000: {
          items: 3,
          nav:true,
          loop: false,
        },
        1600: {
          items: 3,
          nav:true,
          loop: false,
        },
        1920: {
          items: 4,
          nav:true,
          loop: false,
          margin: 20
        }
      }
    });
    $('.play').on('click', function() {
      $this.trigger('play.owl.autoplay', [1000])
    })
    $('.stop').on('click', function() {
      $this.trigger('stop.owl.autoplay')
    })
    //test
});

},

 loadOpenDetailsModal: function(){
  $('#tmp').toggleClass('open-topic-details');


 },

 closeDetailsModal: function(){

  $('#tmp').removeClass('open-topic-details')

 }

  }
  })(owlObj || {});
